import { render, staticRenderFns } from "./MenuGeneticeDistances.vue?vue&type=template&id=20c033b2&scoped=true&"
import script from "./MenuGeneticeDistances.vue?vue&type=script&lang=js&"
export * from "./MenuGeneticeDistances.vue?vue&type=script&lang=js&"
import style0 from "./MenuGeneticeDistances.vue?vue&type=style&index=0&id=20c033b2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c033b2",
  null
  
)

export default component.exports