<template>
  <div>
    <div class="g25-menus-card hidden-g25-dashboard-mobile">
      <div class="g25-menus-card-header">
        <h4>Supervised Models</h4>
      </div>
      <div class="g25-menus-card-body">
        <div>
          <div class="g25-menus-card-item mr-1" @click="$router.push({ name: 'order-result-da', params: { type: DA_RESULT_TABS.PERIODICAL.code }})">
            <supervised-periodical-icons :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            <div class="text">Periodical</div>
          </div>
        </div>
        <div>
          <div class="g25-menus-card-item mr-1" @click="$router.push({ name: 'order-result-da', params: { type: DA_RESULT_TABS.FARMER_HGS.code }})">
            <supervised-hg-icons :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            <div class="text">HG & Farmer</div>
          </div>
        </div>
      </div>
    </div>

    <b-row  class="g25-menus-card hidden-g25-dashboard-desktop">
      <b-col cols="12">
        <div class="g25-menus-card-header mb-1">
          <h4>Supervised Models</h4>
        </div>
      </b-col>
      <b-col cols="6" >
        <div class="g25-menus-card-mobile-item" @click="$router.push({ name: 'order-result-da', params: { type: DA_RESULT_TABS.PERIODICAL.code }})">
          <supervised-periodical-icons :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
          <div class="text mobile-text">Periodical</div>
        </div>
      </b-col>
      <b-col cols="6" >
        <div class="g25-menus-card-mobile-item" @click="$router.push({ name: 'order-result-da', params: { type: DA_RESULT_TABS.FARMER_HGS.code }})">
          <supervised-hg-icons :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
          <div class="text mobile-text">HG & Farmer</div>
        </div>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import { DA_RESULT_TABS } from '@/common/config';
import SupervisedPeriodicalIcons from '@/layouts/icons/g25/SupervisedPeriodicalIcons.vue';
import SupervisedHgIcons from '@/layouts/icons/g25/SupervisedHgIcons.vue';

export default {
  components: {
    SupervisedPeriodicalIcons,
    SupervisedHgIcons,
  },
  data() {
    return {
      DA_RESULT_TABS,
    }
  },
  computed: {

  },
  created() {

  },
}
</script>

<style scoped lang="scss">
.dark-layout .g25-menus-card-header h4 {
  color:  var(--lab-g25-supervised-color) !important;
}
.g25-menus-card-header::before {
  background-color: var(--lab-g25-supervised-color);
}

.dark-layout .g25-menus-card-item .text {
  color:  var(--lab-g25-supervised-color) !important;
  opacity: 0.5;
}
.dark-layout .g25-menus-card-item {
  border-color:  #67d2e175 !important;
}

.g25-menus-card-item:hover {
  transform: scale(1.1);
  border-color:  var(--lab-g25-supervised-color) !important;
  .text {
    color:  var(--lab-g25-supervised-color) !important;
    opacity: 1;
  }
}
</style>
